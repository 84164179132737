import axios from 'axios'

export default {
  state: {
    materials: '',
    materials_quantity: '',
    material_one: ''
  },
  mutations: {
    updateMaterialsOne (state, payload) {
      state.material_one = payload
      console.log(state.material_one)
    },
    updateMaterials (state, payload) {
      state.materials = payload
    },
    updateQuantityMaterials (state, payload) {
      state.materials_quantity = payload
    },
    pushMaterials (state, payload) {
      payload.data.forEach((e) => {
        state.materials.data.push(e)
      })
    }
  },
  actions: {
    async fetchMaterials ({ commit, getters }, payload) {
      commit('clearError')
      commit('setLoading', true)
      try {
        if (payload.type === 'my_learning') {
          const r = await axios({
            method: 'get',
            headers: {
              Authorization: 'Bearer ' + getters.userSecurity.token,
              'X-Api-Key': getters.userSecurity.key
            },
            url: getters.baseURL + 'apicall/coach/getlearninglist',
            params: {
              client_id: payload.client_id,
              platform: payload.platform,
              level: payload.level,
              tags: payload.tags,
              page: payload.current_page,
              order: payload.order_sort,
              search: payload.search
            }
          })
          commit('updateMaterials', r.data)
        } else {
          const x = await axios({
            method: 'get',
            headers: {
              Authorization: 'Bearer ' + getters.userSecurity.token,
              'X-Api-Key': getters.userSecurity.key
            },
            url: getters.baseURL + 'apicall/coach/getmateriallist',
            params: {
              type: payload.type,
              platform: payload.platform,
              level: payload.level,
              tags: payload.tags,
              page: payload.current_page,
              order: payload.order_sort,
              search: payload.search
            }
          })
          commit('updateMaterials', x.data)
        }
        commit('setLoading', false)
      } catch (error) {
        console.log(error.response)
        commit('setError', error)
        commit('setLoading', false)
      }
    },
    async fetchMaterialsOne ({ commit, getters }, payload) {
      commit('clearError')
      commit('setLoading', true)
      try {
        const x = await axios({
          method: 'get',
          headers: {
            Authorization: 'Bearer ' + getters.userSecurity.token,
            'X-Api-Key': getters.userSecurity.key
          },
          url: getters.baseURL + 'apicall/coach/getmaterial',
          params: {
            id: payload
          }
        })
        commit('updateMaterialsOne', x.data)
        commit('setLoading', false)
      } catch (error) {
        console.log(error.response)
        commit('setError', error)
        commit('setLoading', false)
      }
    },
    async quantityMaterials  ({ commit, getters }, payload) {
      commit('clearError')
      try {
        const v = await axios({
          method: 'get',
          headers: {
            Authorization: 'Bearer ' + getters.userSecurity.token,
            'X-Api-Key': getters.userSecurity.key
          },
          url: getters.baseURL + 'apicall/coach/getcounts',
          params: {
            client_id: payload.client_id
          }
        })
        commit('updateQuantityMaterials', v.data)
      } catch (error) {
        console.error(error.response)
        commit('setError', error)
      }
    },
    async fetchShowMore ({ commit, getters }, payload) {
      commit('clearError')
      try {
        const x = await axios({
          method: 'get',
          headers: {
            Authorization: 'Bearer ' + getters.userSecurity.token,
            'X-Api-Key': getters.userSecurity.key
          },
          url: getters.baseURL + 'apicall/coach/getmateriallist',
          params: {
            type: payload.type,
            platform: payload.platform,
            level: payload.level,
            tags: payload.tags,
            page: payload.current_page,
            order: payload.order_sort,
            search: payload.search
          }
        })
        commit('pushMaterials', x.data)
      } catch (error) {
        console.log(error)
        console.log(error.response)
        commit('setError', error)
      }
    },
    async addInLearningList ({ commit, getters }, payload) {
      commit('clearError')
      try {
        await axios({
          method: 'post',
          headers: {
            Authorization: 'Bearer ' + getters.userSecurity.token,
            'X-Api-Key': getters.userSecurity.key
          },
          url: getters.baseURL + 'apicall/coach/addmaterialtoclient',
          params: {
            material_id: payload.material_id,
            client_id: payload.client_id
          }
        })
      } catch (error) {
        console.log(error.response)
        commit('setError', error)
      }
    }
  },
  getters: {
    materials (state) {
      return state.materials
    },
    materials_quantity (state) {
      return state.materials_quantity
    },
    material_one (state) {
      return state.material_one
    }
  }
}
