<template>
  <div class="notifications-message" v-if="message">
    <span class="icon">
      <img src="@/assets/img/icons/icon-message.svg" alt="icon">
    </span>
    <div class="h4 name">{{message.sender.name}}</div>
    <template v-if="message.type === 'text'">
      <div class="color-grey text-md text-message">{{message.text}}</div>
    </template>
    <template v-else-if="message.type === 'image'">
      <div class="color-grey text-md text-message">Image</div>
    </template>
    <template v-else-if="message.type === 'file'">
      <div class="color-grey text-md text-message">File</div>
    </template>
  </div>
</template>

<script>

import { CometChat } from '@cometchat-pro/chat'
import { incomingOtherMessageAlert } from '../../../resources/audio/'
import { CometChatManager } from '@/util/controller'

/**
 * Displays the Notifications Message.
 */
export default {
  name: 'ChatCometNotificationsMessage',
  data () {
    return {
      listenerID: 'message_a_' + new Date().getTime(),
      message: null,
      audio: new Audio(incomingOtherMessageAlert)
    }
  },
  methods: {
    /**
     * Plays composer message sound
     */
    playAudio () {
      this.audio.currentTime = 0
      this.audio.play()
    },
    async getUser () {
      const user = await new CometChatManager().getLoggedInUser()
      return user
    }
  },
  computed: {
    chat_active: function () {
      return this.$store.getters.chat_active
    }
  },
  beforeMount () {
    this.getUser()
      .then((user) => {
        CometChat.addMessageListener(
          this.listenerID,
          new CometChat.MessageListener({
            onTextMessageReceived: textMessage => {
              if (!this.chat_active && user.uid !== textMessage.sender.uid) {
                this.message = textMessage
              }
              this.playAudio()
            },
            onMediaMessageReceived: mediaMessage => {
              if (!this.chat_active && user.uid !== mediaMessage.sender.uid) {
                this.message = mediaMessage
              }
              this.playAudio()
            },
            onCustomMessageReceived: customMessage => {
              if (!this.chat_active && user.uid !== customMessage.sender.uid) {
                this.message = customMessage
              }
              this.playAudio()
            }
          })
        )
      })
  },
  beforeDestroy () {
    CometChat.removeCallListener(this.callListenerId)
  },
  beforeUnmount () {
    CometChat.removeCallListener(this.callListenerId)
  },
  watch: {
    message: {
      handler (v) {
        setTimeout(() => {
          this.message = null
        }, 3000)
      },
      deep: true
    }
  }
}
</script>
