import axios from 'axios'

export default {
  state: {
    projects: ''
  },
  mutations: {
    updateProjects (state, payload) {
      state.projects = payload
    }
  },
  actions: {
    async fetchProjects ({ commit, getters }) {
      commit('clearError')
      try {
        const v = await axios({
          method: 'get',
          headers: {
            Authorization: 'Bearer ' + getters.userSecurity.token,
            'X-Api-Key': getters.userSecurity.key
          },
          url: getters.baseURL + 'apicall/coach/projects'
        })
        commit('updateProjects', v.data)
      } catch (error) {
        console.error(error.response)
        commit('setError', error)
      }
    },
    async sendProjects ({ commit, state, getters }, payload) {
      commit('clearError')
      try {
        await axios.post(getters.baseURL + 'apicall/coach/addproject', payload.image, {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: 'Bearer ' + getters.userSecurity.token,
            'X-Api-Key': getters.userSecurity.key
          },
          params: {
            title: payload.title,
            link: payload.link,
            body: payload.body
          }
        })
      } catch (error) {
        console.error(error.response)
        commit('setError', error)
      }
    }
  },
  getters: {
    projects (state) {
      return state.projects
    }
  }
}
