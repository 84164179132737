import axios from 'axios'

export default {
  state: {
    personalInfo: '',
    personalInfoEdit: '',
    personal_language: [],
    personal_country: []
  },
  mutations: {
    updatePersonalInfo (state, payload) {
      state.personalInfo = payload
      state.personalInfoEdit = JSON.parse(JSON.stringify(payload))
    },
    updateLanguage (state, payload) {
      state.personal_language = payload
    },
    updateCountry (state, payload) {
      state.personal_country = payload
    }
  },
  actions: {
    async fetchPersonalInfo ({ commit, getters }) {
      commit('clearError')
      try {
        const v = await axios({
          method: 'get',
          headers: {
            Authorization: 'Bearer ' + getters.userSecurity.token,
            'X-Api-Key': getters.userSecurity.key
          },
          url: getters.baseURL + 'apicall/coach/profile'
        })
        commit('updatePersonalInfo', v.data)
      } catch (error) {
        console.error(error.response)
        commit('setError', error)
      }
    },
    async sendPersonalInfo ({ commit, state, getters }) {
      commit('clearError')
      try {
        const s = state.personalInfoEdit
        const d = {
          email: s.email,
          phone: s.phone,
          country: s.country,
          location: s.location,
          ln: s.ln,
          fb: s.fb,
          inst: s.inst,
          langs: s.langs
        }
        await axios({
          method: 'post',
          headers: {
            Authorization: 'Bearer ' + getters.userSecurity.token,
            'X-Api-Key': getters.userSecurity.key
          },
          url: getters.baseURL + 'apicall/coach/profilepersonalupdate',
          params: {
            data: JSON.stringify(d)
          }
        })
        commit('updatePersonalInfo', state.personalInfoEdit)
      } catch (error) {
        console.error(error.response)
        commit('setError', error)
      }
    },
    async fetchLanguage ({ commit, getters }) {
      commit('clearError')
      try {
        const v = await axios({
          method: 'get',
          headers: {
            Authorization: 'Bearer ' + getters.userSecurity.token,
            'X-Api-Key': getters.userSecurity.key
          },
          url: getters.baseURL + 'apicall/getlangs'
        })
        commit('updateLanguage', v.data)
      } catch (error) {
        console.error(error.response)
        commit('setError', error)
      }
    },
    async fetchCountry ({ commit, getters }) {
      commit('clearError')
      try {
        const v = await axios({
          method: 'get',
          headers: {
            Authorization: 'Bearer ' + getters.userSecurity.token,
            'X-Api-Key': getters.userSecurity.key
          },
          url: getters.baseURL + 'apicall/getcountries'
        })
        commit('updateCountry', v.data)
      } catch (error) {
        console.error(error.response)
        commit('setError', error)
      }
    }
  },
  getters: {
    personalInfo (state) {
      return state.personalInfo
    },
    personalInfoEdit (state) {
      return state.personalInfoEdit
    },
    personal_language (state) {
      return state.personal_language
    },
    personal_country (state) {
      return state.personal_country
    }
  }
}
