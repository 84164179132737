import axios from 'axios'

export default {
  state: {
    notes_cm: ''
  },
  mutations: {
    editNotesPersonal (state, payload) {
      const i = state.notes_cm.personal.indexOf(state.notes_cm.personal.find(e => e.id === payload.id))
      state.notes_cm.personal.splice(i, 1, payload)
    },
    editNotesClients (state, payload) {
      const i = state.notes_cm.clients.indexOf(state.notes_cm.clients.find(e => e.id === payload.id))
      state.notes_cm.clients.splice(i, 1, payload)
    },
    updateNotesClients (state, payload) {
      state.notes_cm = payload
    },
    addNotesClients (state, payload) {
      state.notes_cm.personal.push(payload)
    }
  },
  actions: {
    async fetchNotesClients ({ commit, getters }, payload) {
      commit('clearError')
      try {
        const x = await axios({
          method: 'get',
          headers: {
            Authorization: 'Bearer ' + getters.userSecurity.token,
            'X-Api-Key': getters.userSecurity.key
          },
          url: getters.baseURL + 'apicall/coach/getnoteslist'
        })
        commit('updateNotesClients', x.data)
      } catch (error) {
        console.error(error)
        console.error(error.response)
        commit('setError', error)
      }
    },
    async saveNotesPersonal ({ commit, getters }, payload) {
      commit('clearError')
      try {
        await axios({
          method: 'post',
          headers: {
            Authorization: 'Bearer ' + getters.userSecurity.token,
            'X-Api-Key': getters.userSecurity.key
          },
          url: getters.baseURL + 'apicall/coach/savenotes',
          params: {
            id: payload.id,
            client_id: 0,
            body: payload.body,
            title: payload.title
          }
        })
        commit('editNotesPersonal', payload)
      } catch (error) {
        console.error(error)
        commit('setError', error)
      }
    },
    async saveNotesClients ({ commit, getters }, payload) {
      commit('clearError')
      try {
        await axios({
          method: 'post',
          headers: {
            Authorization: 'Bearer ' + getters.userSecurity.token,
            'X-Api-Key': getters.userSecurity.key
          },
          url: getters.baseURL + 'apicall/coach/savenotes',
          params: {
            id: payload.id,
            client_id: payload.client_id,
            body: payload.body,
            title: ''
          }
        })
        commit('editNotesClients', payload)
      } catch (error) {
        console.error(error)
        commit('setError', error)
      }
    },
    async addNotesPersonal ({ commit, getters }, payload) {
      commit('clearError')
      try {
        const v = await axios({
          method: 'post',
          headers: {
            Authorization: 'Bearer ' + getters.userSecurity.token,
            'X-Api-Key': getters.userSecurity.key
          },
          url: getters.baseURL + 'apicall/coach/savenotes',
          params: {
            id: 0,
            client_id: 0,
            body: payload.body,
            title: payload.title
          }
        })
        payload.id = v.data.note_id
        commit('addNotesClients', payload)
      } catch (error) {
        console.error(error.response)
        console.error(error)
        commit('setError', error)
      }
    }
  },
  getters: {
    notes_cm (state) {
      return state.notes_cm
    }
  }
}
