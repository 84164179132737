import { CometChat } from '@cometchat-pro/chat'
import router from './../../../../router'
import store from './../../../../store'

import * as enums from '../../../../util/enums.js'

export class CallAlertManager {
  callListenerId = 'incoming_call_' + new Date().getTime()

  attachListeners (callback) {
    CometChat.addCallListener(
      this.callListenerId,
      new CometChat.CallListener({
        onIncomingCallReceived: (call) => {
          callback(enums.INCOMING_CALL_RECEIVED, call)
          if (router.currentRoute.path !== '/workspace') {
            router.push('/workspace')
          }
          store.commit('change_tab_active', 'tab-1')
        },
        onIncomingCallCancelled: (call) => {
          callback(enums.INCOMING_CALL_CANCELLED, call)
        }
      })
    )
  }

  removeListeners () {
    CometChat.removeCallListener(this.callListenerId)
  }
}
