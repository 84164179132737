import axios from 'axios'

export default {
  state: {
    user_achievements: '',
    user_achievements_edit: '',
    options_federation: [],
    options_cert: []
  },
  mutations: {
    updateUserAchievements (state, payload) {
      state.user_achievements = payload
    },
    updateUserAchievementsEdit (state, payload) {
      console.log('START')
      const c = JSON.parse(JSON.stringify(payload))
      if (state.options_federation.length) {
        c.achievments.federation_id = c.achievments.federation_id ? state.options_federation.find((e) => +e.id === +c.achievments.federation_id) : ''
      }
      if (state.options_cert.length) {
        if (c.certifications) {
          c.certifications.forEach((a, i) => {
            c.certifications[i] = state.options_cert.find((e) => +e.id === +c.certifications[i].cert_id)
          })
        } else {
          c.certifications = []
        }
      }
      state.user_achievements_edit = c
    },
    updateOptionsFederation (state, payload) {
      state.options_federation = payload
    },
    updateOptionsCert (state, payload) {
      state.options_cert = payload
    }
  },
  actions: {
    async fetchUserAchievements ({ commit, getters }, payload = true) {
      commit('clearError')
      try {
        const v = await axios({
          method: 'get',
          headers: {
            Authorization: 'Bearer ' + getters.userSecurity.token,
            'X-Api-Key': getters.userSecurity.key
          },
          url: getters.baseURL + 'apicall/coach/achievements'
        })
        console.log(v)
        if (payload) {
          commit('updateUserAchievementsEdit', v.data)
        }
        commit('updateUserAchievements', v.data)
      } catch (error) {
        console.error(error.response)
        commit('setError', error)
      }
    },
    async sendUserAchievements ({ commit, state, getters }) {
      commit('clearError')
      const a = state.user_achievements_edit?.achievments
      const c = {
        since: a.since,
        school: a.school,
        federation_id: a.federation_id ? a.federation_id.id : null,
        federation_role: a.federation_role,
        management_experience: a.management_experience,
        leadership_experience: a.leadership_experience,
        led_a_group: a.led_a_group,
        certs: state.user_achievements_edit.certifications.map(e => e.id),
        how_learn_id: 1
      }
      try {
        await axios({
          method: 'post',
          headers: {
            Authorization: 'Bearer ' + getters.userSecurity.token,
            'X-Api-Key': getters.userSecurity.key
          },
          url: getters.baseURL + 'apicall/coach/achievementscreate',
          params: {
            data: c
          }
        })
        // dispatch('fetchUserInformation')
      } catch (error) {
        console.error(error.response)
        commit('setError', error)
      }
    },
    async fetchOptionsFederation ({ commit, getters }) {
      commit('clearError')
      try {
        const v = await axios({
          method: 'get',
          headers: {
            Authorization: 'Bearer ' + getters.userSecurity.token,
            'X-Api-Key': getters.userSecurity.key
          },
          url: getters.baseURL + 'apicall/coach/getfederations'
        })
        commit('updateOptionsFederation', v.data)
      } catch (error) {
        console.error(error.response)
        commit('setError', error)
      }
    },
    async fetchOptionsCert ({ commit, getters }) {
      commit('clearError')
      try {
        const v = await axios({
          method: 'get',
          headers: {
            Authorization: 'Bearer ' + getters.userSecurity.token,
            'X-Api-Key': getters.userSecurity.key
          },
          url: getters.baseURL + 'apicall/coach/getcertifications'
        })
        commit('updateOptionsCert', v.data)
      } catch (error) {
        console.error(error.response)
        commit('setError', error)
      }
    }
  },
  getters: {
    user_achievements (state) {
      return state.user_achievements
    },
    user_achievements_edit (state) {
      return state.user_achievements_edit
    },
    options_federation (state) {
      return state.options_federation
    },
    options_cert (state) {
      return state.options_cert
    }
  }
}
