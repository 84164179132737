import axios from 'axios'

export default {
  state: {
    user_information: ''
  },
  mutations: {
    updateUserInformation (state, payload) {
      state.user_information = payload
    }
  },
  actions: {
    async fetchUserInformation ({ commit, getters }) {
      commit('clearError')
      try {
        const a = await axios({
          method: 'get',
          headers: {
            Authorization: 'Bearer ' + getters.userSecurity.token,
            'X-Api-Key': getters.userSecurity.key
          },
          url: getters.baseURL + 'apicall/coach/viewcoachingareas'
        })
        const b = await axios({
          method: 'get',
          headers: {
            Authorization: 'Bearer ' + getters.userSecurity.token,
            'X-Api-Key': getters.userSecurity.key
          },
          url: getters.baseURL + 'apicall/coach/viewproblemstohelp'
        })
        const c = await axios({
          method: 'get',
          headers: {
            Authorization: 'Bearer ' + getters.userSecurity.token,
            'X-Api-Key': getters.userSecurity.key
          },
          url: getters.baseURL + 'apicall/coach/viewcoachskills'
        })
        const d = {
          a: {
            type: 'career_coaching_areas',
            title: 'Name the career coaching area you consider yourself and rank it',
            options: a.data
          },
          b: {
            type: 'problem_to_helps',
            title: 'What problem can you help with?',
            options: b.data
          },
          c: {
            type: 'coach_skills',
            title: 'Define skills that you best possess as a career coach and rank it',
            options: c.data
          }
        }
        commit('updateUserInformation', d)
      } catch (error) {
        console.error(error.response)
        commit('setError', error)
      }
    },
    async sendUserInformation ({ commit, state, getters, dispatch }, payload) {
      commit('clearError')
      const a = []
      if (payload.length) {
        payload.forEach((e) => {
          e.answer_id.forEach((s) => {
            a.push({
              type: e.type,
              answer_id: s
            })
          })
        })
      }
      try {
        await axios({
          method: 'post',
          headers: {
            Authorization: 'Bearer ' + getters.userSecurity.token,
            'X-Api-Key': getters.userSecurity.key
          },
          url: getters.baseURL + 'apicall/coach/saveansers',
          params: {
            data: JSON.stringify(a)
          }
        })
        dispatch('fetchUserInformation')
      } catch (error) {
        console.error(error.response)
        commit('setError', error)
      }
    }
  },
  getters: {
    user_information (state) {
      return state.user_information
    }
  }
}
