import axios from 'axios'

export default {
  state: {
    plan: '',
    roadmap: ''
  },
  mutations: {
    updatePlan (state, payload) {
      state.plan = payload
    },
    updatePlanItem (state, payload) {
      const u = state.plan.indexOf(state.plan.find(e => e.id === payload.id))
      state.plan.splice(u, 1, payload)
    },
    pushPlan (state, payload) {
      state.plan.push(payload)
    },
    updateRoadmap (state, payload) {
      state.roadmap = payload
    }
  },
  actions: {
    async fetchPlan ({ commit, getters }) {
      commit('clearError')

      try {
        // const v = await base(1000)
        const r = await axios({
          method: 'get',
          headers: {
            Authorization: 'Bearer ' + getters.userSecurity.token,
            'X-Api-Key': getters.userSecurity.key
          },
          url: getters.baseURL + 'apicall/coach/getdevplan?client_id=' + getters.activeUser
        })
        commit('updatePlan', r.data)
      } catch (error) {
        console.error(error.response)
        commit('setError', error)
      }
    },
    async fetchRoadmap ({ commit, getters }, payload) {
      commit('clearError')
      try {
        const r = await axios({
          method: 'get',
          headers: {
            Authorization: 'Bearer ' + getters.userSecurity.token,
            'X-Api-Key': getters.userSecurity.key
          },
          url: getters.baseURL + 'apicall/coach/getdevplan?client_id=' + payload
        })
        commit('updateRoadmap', r.data)
      } catch (error) {
        console.error(error.response)
        commit('setError', error)
      }
    },
    async sendPlan ({ commit, getters }, payload) {
      commit('clearError')
      try {
        const s = await axios({
          method: 'post',
          headers: {
            Authorization: 'Bearer ' + getters.userSecurity.token,
            'X-Api-Key': getters.userSecurity.key
          },
          url: getters.baseURL + 'apicall/coach/adddevplan',
          params: payload
        })
        console.log(s)
        commit('pushPlan', payload)
        commit('setLoading', false)
      } catch (error) {
        console.error(error.response)
        commit('setError', error)
      }
    },
    async savePlan ({ commit, getters }, payload) {
      commit('clearError')
      try {
        await axios({
          method: 'post',
          headers: {
            Authorization: 'Bearer ' + getters.userSecurity.token,
            'X-Api-Key': getters.userSecurity.key
          },
          url: getters.baseURL + 'apicall/coach/savedevplan',
          params: payload
        })
        commit('updatePlanItem', payload)
        commit('setLoading', false)
      } catch (error) {
        console.error(error.response)
        commit('setError', error)
      }
    }
  },
  getters: {
    plan (state) {
      return state.plan
    },
    one_plan: (state) => (id) => {
      return state.plan.find((e) => {
        return e.id === id
      })
    },
    roadmap (state) {
      return state.roadmap
    }
  }
}
