import axios from 'axios'

export default {
  state: {
    events: []
  },
  mutations: {
    updateEvents (state, payload) {
      state.events = payload
    },
    pushEvents (state, payload) {
      state.events.push(payload)
    },
    saveEditEvents (state, payload) {
      const i = state.events.indexOf(state.events.find(e => e.id === payload.id))
      state.events.splice(i, 1, payload)
    }
  },
  actions: {
    async fetchEvents ({ commit, getters }, payload) {
      commit('clearError')
      try {
        // const v = await base(1000)
        const x = await axios({
          method: 'post',
          headers: {
            Authorization: 'Bearer ' + getters.userSecurity.token,
            'X-Api-Key': getters.userSecurity.key
          },
          url: getters.baseURL + 'apicall/coach/getevents',
          params: {
            filter_start: payload.start,
            filter_end: payload.end
          }
        })
        commit('updateEvents', x.data)
      } catch (error) {
        console.error(error)
        console.error(error.response)
        commit('setError', error)
      }
    },
    async sendEventsAdd ({ commit, getters }, payload) {
      commit('clearError')

      function dateDay (e) {
        const t = new Date(e)
        const date = t.getFullYear() + '-' + ('0' + (t.getMonth() + 1)).slice(-2) + '-' + ('0' + t.getDate()).slice(-2)
        const d = {
          date: date
        }
        return d
      }

      try {
        await axios({
          method: 'post',
          headers: {
            Authorization: 'Bearer ' + getters.userSecurity.token,
            'X-Api-Key': getters.userSecurity.key
          },
          url: getters.baseURL + 'apicall/coach/addevent',
          params: {
            title: payload.title,
            description: payload.description,
            event_start: payload.t_start,
            event_end: payload.t_end,
            event_date: dateDay(payload.event_date).date + 'T00:00:00',
            client_id: payload.client_id
          }
        })
        commit('pushEvents', payload)
      } catch (error) {
        console.error(error)
        console.error(error.response)
        commit('setError', error)
      }
    },
    async sendEventsEdit ({ commit }, payload) {
      commit('clearError')

      // base
      function base (ms) {
        return new Promise(resolve => {
          // setTimeout(resolve, ms)
          setTimeout(() => {
            resolve(
              'DONE'
            )
          }, ms)
        })
      }
      // base the end

      try {
        const v = await base(1000)
        console.log(v)
        commit('saveEditEvents', payload)
      } catch (error) {
        console.error(error.response)
        commit('setError', error)
      }
    }
  },
  getters: {
    events (state) {
      return state.events
    },
    oneEvent: (state) => (id) => {
      return state.events.find((e) => {
        return +e.id === +id
      })
    }
  }
}
