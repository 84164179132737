import Vue from 'vue'
import Vuex from 'vuex'
import Shared from './shared'
import User from './user/'
import Workspace from './workspace/'
import Events from './events'
import Clients from './clients'
import Projects from './projects'
import Notes from './notes'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    baseURL: 'https://api.competenzlab.io/',
    loginURL: 'https://competenzlab.io/login',
    user_chat_login_is: false,
    chat_active: false
  },
  mutations: {
    toggle_chat_active (state, payload) {
      state.chat_active = payload
    },
    toggle_user_chat (state, payload) {
      state.user_chat_login_is = payload
    },
    change_incoming_call (state, payload) {
      state.incoming_call = payload
    }
  },
  getters: {
    loginURL (state, getters, rootState) {
      return rootState.loginURL
    },
    user_chat_login_is (state) {
      return state.user_chat_login_is
    },
    chat_active (state) {
      return state.chat_active
    },
    incoming_call (state) {
      return state.incoming_call
    }
  },
  modules: {
    Shared,
    User,
    Workspace,
    Events,
    Clients,
    Projects,
    Notes
  }
})
