import axios from 'axios'

export default {
  state: {
    basicprofile: '',
    basicprofileEdit: '',
    photoRemove: null,
    photo: null,
    basicprofile_tags: []
  },
  mutations: {
    updatePhoto (state, payload) {
      state.photo = payload
    },
    removePhoto (state) {
      state.photoRemove = true
      state.basicprofileEdit.photo = null
    },
    updatePhotoURL (state, payload) {
      state.basicprofileEdit.photo = payload
      state.photoRemove = null
      state.photo = null
    },
    updateBasicprofile (state, payload) {
      state.basicprofile = payload
      state.basicprofileEdit = JSON.parse(JSON.stringify(payload))
    },
    updateTags (state, payload) {
      state.basicprofile_tags = payload
    }
  },
  actions: {
    async fetchBasicprofile ({ commit, getters }) {
      commit('clearError')
      try {
        const v = await axios({
          method: 'get',
          headers: {
            Authorization: 'Bearer ' + getters.userSecurity.token,
            'X-Api-Key': getters.userSecurity.key
          },
          url: getters.baseURL + 'apicall/coach/profile'
        })
        commit('updateBasicprofile', v.data)
      } catch (error) {
        console.error(error.response)
        commit('setError', error)
      }
    },
    async sendBasicprofile ({ commit, state, getters }) {
      commit('clearError')
      try {
        const s = state.basicprofileEdit
        const d = {
          name: s.name,
          background: s.background,
          industries: s.tags.map(e => e.id),
          photo: s.photo
        }
        await axios({
          method: 'post',
          headers: {
            Authorization: 'Bearer ' + getters.userSecurity.token,
            'X-Api-Key': getters.userSecurity.key
          },
          url: getters.baseURL + 'apicall/coach/profilemainupdate',
          params: {
            data: JSON.stringify(d)
          }
        })
        if (state.photo) {
          const c = await axios.post(getters.baseURL + 'apicall/coach/storephoto', state.photo, {
            headers: {
              'Content-Type': 'multipart/form-data',
              Authorization: 'Bearer ' + getters.userSecurity.token,
              'X-Api-Key': getters.userSecurity.key
            }
          })
          commit('updatePhotoURL', c.data.path)
        }
        if (state.photoRemove) {
          await axios.post(getters.baseURL + 'apicall/coach/storephoto', { photo: null }, {
            headers: {
              'Content-Type': 'multipart/form-data',
              Authorization: 'Bearer ' + getters.userSecurity.token,
              'X-Api-Key': getters.userSecurity.key
            }
          })
          commit('updatePhotoURL', null)
        }
        commit('updateBasicprofile', state.basicprofileEdit)
      } catch (error) {
        console.error(error.response)
        commit('setError', error)
      }
    },
    async fetchTags ({ commit, getters }) {
      commit('clearError')
      try {
        const v = await axios({
          method: 'get',
          headers: {
            Authorization: 'Bearer ' + getters.userSecurity.token,
            'X-Api-Key': getters.userSecurity.key
          },
          url: getters.baseURL + 'apicall/coach/getindustries'
        })
        commit('updateTags', v.data)
      } catch (error) {
        console.error(error.response)
        commit('setError', error)
      }
    }
  },
  getters: {
    updatePhoto (state) {
      return state.photo
    },
    basicprofile (state) {
      return state.basicprofile
    },
    basicprofileEdit (state) {
      return state.basicprofileEdit
    },
    basicprofile_tags (state) {
      return state.basicprofile_tags
    }
  }
}
