<template>
  <div
    class="lb-w-chat-confirm"
    v-if="incomingCall"
  >
    <div class="user-image">
      <img v-if="incomingCall.sender.avatar" :src="incomingCall.sender.avatar" alt="image">
      <img v-else src="@/assets/img/default-user-avatar.png" alt="image">
    </div>
    <div class="text-center text-md lb-mb-15">
      <span class="badge-ml bg-grey color-dark-2">{{ incomingCall.sender.name }}</span>
    </div>
    <!--
    <div>
      <template v-if="incomingCall.type === 'video'">
        <div class="text-cente text-md">
          Video call
        </div>
      </template>
      <template v-else>
        <div class="text-center text-md">
          Audio call
        </div>
      </template>
    </div>
    -->
    <div class="row justify-content-between">
      <div class="col-auto">
        <button
          class="btn btn-cr-icon"
          @click="rejectCall"
        >
          <span class="icon"><img src="@/assets/img/icons/icon-phone-red.svg" alt="image"></span>
        </button>
      </div>
      <div class="col-auto">
        <button
          class="btn btn-cr-icon"
          @click="acceptCall"
        >
          <span class="icon"><img src="@/assets/img/icons/icon-phone-green.svg" alt="image"></span>
        </button>
      </div>
    </div>
  </div>
</template>

<!--eslint-disable-->
<script>
import { CometChat } from '@cometchat-pro/chat'

import { COMETCHAT_CONSTANTS, DEFAULT_OBJECT_PROP } from '../../../../resources/constants'

import { propertyCheck, cometChatCommon } from '../../../../mixins/'

import { CometChatManager } from '../../../../util/controller'
import { SvgAvatar } from '../../../../util/svgavatar'
import * as enums from '../../../../util/enums.js'

import { CallAlertManager } from './controller'

import { incomingCallAlert } from '../../../../resources/audio/'

let incomingAlert;
let callAlertManager;

/**
 * Displays the incoming call alert.
 */
export default {
  name: "ChatCometIncomingCall",
  mixins: [propertyCheck, cometChatCommon],
  props: {
    /**
     * Theme of the UI.
     */
    theme: { ...DEFAULT_OBJECT_PROP },
    /**
     * The call object that is in progress.
     */
    callInProgress: { ...DEFAULT_OBJECT_PROP },
  },
  data() {
    return {
      incomingCall: null,
    };
  },
  computed: {
    /**
     * Local string constants.
     */
    STRINGS() {
      return COMETCHAT_CONSTANTS;
    },
  },
  methods: {
    /**
     * Plays the incoming alert tone
     */
    playIncomingAlert() {
      try {
        incomingAlert.currentTime = 0;
        if (typeof incomingAlert.loop == "boolean") {
          incomingAlert.loop = true;
        } else {
          incomingAlert.addEventListener(
            "ended",
            function () {
              this.currentTime = 0;
              this.play();
            },
            false
          );
        }
        incomingAlert.play();
      } catch (error) {
        this.logError("Error playing incoming alert", error);
      }
    },
    /**
     * Pauses the incoming call alert
     */
    pauseIncomingAlert() {
      try {
        incomingAlert.pause();
      } catch (error) {
        this.logError("Error pausing incoming alert", error);
      }
    },
    /**
     * Marks a message as read
     */
    markMessageAsRead(message) {
      try {
        if (this.hasProperty(message, "readAt") === false) {
          CometChat.markAsRead(message);
        }
      } catch (error) {
        this.logError("Error marking message as read", error);
      }
    },
    /**
     * Function to recieve incoming call
     */
    async incomingCallReceived(incomingCall) {
      const activeCall = CometChat.getActiveCall();

      if (activeCall) {
        try {
          const rejectedCall = await CometChat.rejectCall(
            incomingCall.sessionId,
            CometChat.CALL_STATUS.BUSY
          );

          this.markMessageAsRead(incomingCall);
          this.emitAction("rejectedIncomingCall", {
            incomingCall,
            rejectedCall,
          });
        } catch (error) {
          this.emitAction("callError", { error });
          this.logError("Call rejection failed with error:", error);
        }
      } else if (this.incomingCall === null) {
        try {
          this.playIncomingAlert();

          if (incomingCall.sender.avatar === false) {
            const uid = incomingCall.sender.uid;
            const char = incomingCall.sender.name.charAt(0).toUpperCase();

            incomingCall.sender.avatar = SvgAvatar.getAvatar(uid, char);
          }

          this.incomingCall = incomingCall;
        } catch (error) {
          this.logError("Call rejection failed with error:", error);
        }
      }
    },
    /**
     * Function to cancel incoming call
     */
    incomingCallCancelled() {
      try {
        this.pauseIncomingAlert();
        this.incomingCall = null;
      } catch (error) {
        this.logError("Call cancel failed with error:", error);
      }
    },
    /**
     * Function to reject incoming call
     */
    async rejectCall() {
      this.pauseIncomingAlert();

      try {
        const rejectedCall = await CometChatManager.rejectCall(
          this.incomingCall.sessionId,
          CometChat.CALL_STATUS.REJECTED
        );

        this.emitAction("rejectedIncomingCall", {
          incomingCall: this.incomingCall,
          rejectedCall,
        });
      } catch (error) {
        this.emitAction("callError", { error });
      } finally {
        this.incomingCall = null;
      }
    },
    /**
     * Function to accept call
     */
    acceptCall() {
      try {
        this.pauseIncomingAlert();
        this.emitAction('acceptIncomingCall', {
          incomingCall: this.incomingCall,
        });

        setTimeout(() => {
          this.incomingCall = null
        }, 100);
        console.log(this.incomingCall)
      } catch (e) {
        this.logError(e);
      }
    },
    /**
     * This function handles the call listeners
     */
    callScreenUpdateHandler(key, call) {
      switch (key) {
        case enums.INCOMING_CALL_RECEIVED:
          this.incomingCallReceived(call);
          break;
        case enums.INCOMING_CALL_CANCELLED:
          this.incomingCallCancelled();
          break;
        default:
          break;
      }
    },
  },
  beforeMount() {
    incomingAlert = new Audio(incomingCallAlert);

    callAlertManager = new CallAlertManager();
    callAlertManager.attachListeners(this.callScreenUpdateHandler);
  },
  beforeDestroy() {
    if (callAlertManager) {
      callAlertManager.removeListeners();
      callAlertManager = null;
    }
  },
  beforeUnmount() {
    if (callAlertManager) {
      callAlertManager.removeListeners();
      callAlertManager = null;
    }
  }
};
</script>
