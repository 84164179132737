import axios from 'axios'

export default {
  state: {
    clients: '',
    clientsFilters: '',
    info_client_one: ''
  },
  mutations: {
    updateClients (state, payload) {
      state.clients = payload
    },
    updateClientsFilters (state, payload) {
      state.clientsFilters = payload
    },
    updateClientOne (state, payload) {
      state.info_client_one = payload
    }
  },
  actions: {
    async fetchClients ({ commit }) {
      commit('clearError')
      commit('setLoading', true)

      // base
      function base (ms) {
        return new Promise(resolve => {
          // setTimeout(resolve, ms)
          setTimeout(() => {
            resolve(
              [
                {
                  imageUrl: require('@/assets/img/image-user-02.jpg'),
                  name: 'Kathryn Murphy',
                  prof: 'Front-End Developer',
                  specifications: ['Digital', 'Marketing', 'Strategic planning', 'Business plan development', 'Presentation', 'Online presence'],
                  languages: ['Russian', 'English', 'Spanish'],
                  area: 'Development',
                  goal: 'Change career path',
                  detail: 'I am a creative writer, blogger, world traveler, and proud Latina leader. I have a passion for helping people improve their web presence, reach new customers, and grow online using Google tools. As the CEO of Crossing Bridges Consulting, she helps entrepreneurs with event planning and building an online presence. She has more than 19 years of experience working with small businesses in areas such as marketing and strategic planning. Since 1998, she has volunteered with the Greater Austin Hispanic Chamber of Commerce. Her blog “Chica About Town” has allowed her to travel and meet people from all walks of life. She is the chica about town who wants to show you how to make it big in your town.',
                  email: 'kathryn.murphy@gmail.com',
                  location: 'USA, New York',
                  linkedIn: 'https://linkedin.com/in/kathrynmurphy/',
                  facebook: 'https://facebook.com/kathrynmurphy'
                },
                {
                  imageUrl: require('@/assets/img/image-user-02.jpg'),
                  name: 'Jamie Arnold',
                  prof: 'Senior Angular Developer',
                  specifications: ['HTML5', 'CSS3', 'Strategic planning', 'Business plan development', 'Presentation', 'Online presence'],
                  languages: ['English', 'Spanish'],
                  area: 'Development',
                  goal: 'Change career path',
                  detail: 'I am a creative writer, blogger, world traveler, and proud Latina leader. I have a passion for helping people improve their web presence, reach new customers, and grow online using Google tools. As the CEO of Crossing Bridges Consulting, she helps entrepreneurs with event planning and building an online presence. She has more than 19 years of experience working with small businesses in areas such as marketing and strategic planning. Since 1998, she has volunteered with the Greater Austin Hispanic Chamber of Commerce. Her blog “Chica About Town” has allowed her to travel and meet people from all walks of life. She is the chica about town who wants to show you how to make it big in your town.',
                  email: 'kathryn.murphy@gmail.com',
                  location: 'UK, London',
                  linkedIn: 'https://linkedin.com/in/kathrynmurphy/',
                  facebook: 'https://facebook.com/kathrynmurphy'
                },
                {
                  imageUrl: require('@/assets/img/image-user-02.jpg'),
                  name: 'Lloyd Weber',
                  prof: 'UX/UI Designer',
                  specifications: ['User Interface', 'User Experience', 'Strategic planning', 'Business plan development', 'Presentation', 'Online presence'],
                  languages: ['English'],
                  area: 'Design',
                  goal: 'Change career path',
                  detail: 'I am a creative writer, blogger, world traveler, and proud Latina leader. I have a passion for helping people improve their web presence, reach new customers, and grow online using Google tools. As the CEO of Crossing Bridges Consulting, she helps entrepreneurs with event planning and building an online presence. She has more than 19 years of experience working with small businesses in areas such as marketing and strategic planning. Since 1998, she has volunteered with the Greater Austin Hispanic Chamber of Commerce. Her blog “Chica About Town” has allowed her to travel and meet people from all walks of life. She is the chica about town who wants to show you how to make it big in your town.',
                  email: 'kathryn.murphy@gmail.com',
                  location: 'USA, New York',
                  linkedIn: 'https://linkedin.com/in/kathrynmurphy/',
                  facebook: 'https://facebook.com/kathrynmurphy'
                },
                {
                  imageUrl: require('@/assets/img/image-user-02.jpg'),
                  name: 'Lionel Vaughn',
                  prof: 'Middle Android Developer',
                  specifications: ['JavaScript', 'Python', 'Strategic planning', 'Business plan development', 'Presentation', 'Online presence'],
                  languages: ['English', 'Italian'],
                  area: 'Development',
                  goal: 'Change career path',
                  detail: 'I am a creative writer, blogger, world traveler, and proud Latina leader. I have a passion for helping people improve their web presence, reach new customers, and grow online using Google tools. As the CEO of Crossing Bridges Consulting, she helps entrepreneurs with event planning and building an online presence. She has more than 19 years of experience working with small businesses in areas such as marketing and strategic planning. Since 1998, she has volunteered with the Greater Austin Hispanic Chamber of Commerce. Her blog “Chica About Town” has allowed her to travel and meet people from all walks of life. She is the chica about town who wants to show you how to make it big in your town.',
                  email: 'kathryn.murphy@gmail.com',
                  location: 'Italy, Milan',
                  linkedIn: 'https://linkedin.com/in/kathrynmurphy/',
                  facebook: 'https://facebook.com/kathrynmurphy'
                }
              ]
            )
          }, ms)
        })
      }
      // base the end

      try {
        const v = await base(500)
        commit('updateClients', v)
        commit('setLoading', false)
      } catch (error) {
        commit('setError', error)
        commit('setLoading', false)
      }
    },
    async fetchClientsFilters ({ commit }) {
      commit('clearError')
      commit('setLoading', true)

      // base
      function base (ms) {
        return new Promise(resolve => {
          // setTimeout(resolve, ms)
          setTimeout(() => {
            resolve(
              {
                language: [
                  {
                    name: 'English',
                    quantity: 39
                  },
                  {
                    name: 'Russian',
                    quantity: 9
                  },
                  {
                    name: 'Spanish',
                    quantity: 12
                  },
                  {
                    name: 'Swedish',
                    quantity: 23
                  },
                  {
                    name: 'Polish',
                    quantity: 10
                  },
                  {
                    name: 'Deutsh',
                    quantity: 16
                  },
                  {
                    name: 'Russian',
                    quantity: 9
                  }
                ],
                areas: [
                  {
                    name: 'Digital marketing',
                    quantity: 39
                  },
                  {
                    name: 'Graphic Design',
                    quantity: 9
                  },
                  {
                    name: 'Development',
                    quantity: 12
                  },
                  {
                    name: 'Software',
                    quantity: 23
                  },
                  {
                    name: 'Management',
                    quantity: 10
                  },
                  {
                    name: 'Project',
                    quantity: 16
                  },
                  {
                    name: 'Software',
                    quantity: 23
                  }
                ],
                goal: [
                  {
                    name: 'Change career path',
                    quantity: 39
                  },
                  {
                    name: 'Relocation',
                    quantity: 9
                  },
                  {
                    name: 'Skill Development',
                    quantity: 12
                  },
                  {
                    name: 'Training',
                    quantity: 23
                  },
                  {
                    name: 'Learning new skills',
                    quantity: 10
                  }
                ]
              }
            )
          }, ms)
        })
      }
      // base the end

      try {
        const v = await base(500)
        commit('updateClientsFilters', v)
        commit('setLoading', false)
      } catch (error) {
        commit('setError', error)
        commit('setLoading', false)
      }
    },
    async fetchClientOne ({ commit, getters }, payload) {
      commit('clearError')
      commit('setLoading', true)
      try {
        const x = await axios({
          method: 'get',
          headers: {
            Authorization: 'Bearer ' + getters.userSecurity.token,
            'X-Api-Key': getters.userSecurity.key
          },
          url: getters.baseURL + 'apicall/coach/getclientinfo',
          params: {
            id: payload
          }
        })
        console.log(x)
        commit('updateClientOne', x.data)
        commit('setLoading', false)
      } catch (error) {
        console.log(error.response)
        commit('setError', error)
        commit('setLoading', false)
      }
    }
  },
  getters: {
    clients (state) {
      return state.clients
    },
    clientsFilters (state) {
      return state.clientsFilters
    },
    info_client_one (state) {
      return state.info_client_one
    }
  }
}
