import Vue from 'vue'
import VueRouter from 'vue-router'
import AuthGuard from './auth-guard'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: () => import('@/views/Profile/index'),
    beforeEnter: AuthGuard
  },
  {
    path: '/profile/profile-edit/main',
    component: () => import('@/views/Profile/Edit-profile/index'),
    beforeEnter: AuthGuard,
    children: [
      {
        path: '/profile/profile-edit/main',
        component: () => import('@/views/Profile/Edit-profile/Main'),
        beforeEnter: AuthGuard
      },
      {
        path: '/profile/profile-edit/person-info',
        component: () => import('@/views/Profile/Edit-profile/PersonInfo'),
        beforeEnter: AuthGuard
      },
      {
        path: '/profile/profile-edit/project',
        component: () => import('@/views/Profile/Edit-profile/Project'),
        beforeEnter: AuthGuard
      }
    ]
  },
  {
    path: '/workspace',
    component: () => import('@/views/Workspace'),
    beforeEnter: AuthGuard
  },
  {
    path: '/plan-schedule',
    component: () => import('@/views/PlanSchedule'),
    beforeEnter: AuthGuard
  },
  {
    path: '/notes',
    component: () => import('@/views/Notes'),
    beforeEnter: AuthGuard
  },
  {
    path: '/find-clients',
    component: () => import('@/views/FindClients'),
    beforeEnter: AuthGuard
  },
  {
    path: '*',
    meta: { hideSideNav: true },
    component: () => import('@/views/PageNotFound')
  }
]
export default new VueRouter({
  routes,
  mode: 'history'
})
