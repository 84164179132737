import Vue from 'vue'
import App from './App.vue'
import Vuelidate from 'vuelidate'
import VCalendar from 'v-calendar'
import router from './router'
import store from './store'
import axios from 'axios'
import './assets/scss/style.scss'
import { utils } from './utils.js'
import { CometChat } from '@cometchat-pro/chat'

Vue.prototype.$utils = utils
Vue.prototype.$axios = axios

Vue.use(VCalendar, {
  componentPrefix: 'vc'
})

Vue.use(Vuelidate)

Vue.config.productionTip = false

new Vue({
  data () {
    return {
      baseURL: 'https://api.competenzlab.io/',
      authKey: '90ab3f48dd56992dfd38a2689f7af8d461aa0c0e'
    }
  },
  router,
  store,
  render: h => h(App),
  computed: {
    user: function () {
      return this.$store.getters.user
    },
    user_chat_login_is: function () {
      return this.$store.getters.user_chat_login_is
    }
  },
  watch: {
    user: {
      handler (v) {
        // const uid = 'SUPERHERO3'
        if (v && !this.user_chat_login_is) {
          CometChat.login(v.user_id, this.authKey).then(
            (user) => {
              this.$store.commit('toggle_user_chat', true)
              console.log('Saccess login')
            },
            (e) => {
              console.log(e)
            }
          )
        }
      },
      deep: true
    }
  },
  beforeCreate () {
    // const appID = '1947353c26154f98'
    // const region = 'eu'
    const appID = '1959255f860d6a6f'
    const region = 'eu'
    const appSetting = new CometChat.AppSettingsBuilder()
      .subscribePresenceForAllUsers()
      .setRegion(region)
      .build()
    CometChat.init(appID, appSetting).then(
      () => {
        console.log('Saccess init')
        // if (this.user && !this.user_chat_login_is) {
        //   console.log('Saccess init')
        // }
      },
      (e) => {
        console.log(e)
      }
    )
  }
}).$mount('#app')
