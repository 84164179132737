import axios from 'axios'

export default {
  state: {
    notes_content: ''
  },
  mutations: {
    updateNotes (state, payload) {
      state.notes_content = payload
    }
  },
  actions: {
    async fetchNotes ({ commit, getters }) {
      commit('clearError')

      try {
        const r = await axios({
          method: 'get',
          headers: {
            Authorization: 'Bearer ' + getters.userSecurity.token,
            'X-Api-Key': getters.userSecurity.key
          },
          url: getters.baseURL + 'apicall/coach/getnotes?client_id=' + getters.activeUser
        })
        // console.log(r)
        commit('updateNotes', r.data)
      } catch (error) {
        console.error(error)
        commit('setError', error)
      }
    },
    async sendNotes ({ commit, getters }, payload) {
      commit('clearError')
      try {
        await axios({
          method: 'post',
          headers: {
            Authorization: 'Bearer ' + getters.userSecurity.token,
            'X-Api-Key': getters.userSecurity.key
          },
          url: getters.baseURL + 'apicall/coach/savenotes',
          params: {
            id: payload.id,
            client_id: getters.activeUser,
            body: payload.body,
            title: ''
          }
        })
        commit('setLoading', false)
      } catch (error) {
        console.error(error)
        commit('setError', error)
      }
    }
  },
  getters: {
    notes_content (state) {
      return state.notes_content
    }
  }
}
