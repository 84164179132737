import axios from 'axios'

export default {
  state: {
    goals: ''
  },
  mutations: {
    updateGoals (state, payload) {
      state.goals = payload
    },
    pushGoals (state, payload) {
      state.goals.uncompleted.push(payload)
    },
    changeGoals (state, payload) {
      const u = state.goals.uncompleted.indexOf(state.goals.uncompleted.find(e => e.id === payload.id))
      const c = state.goals.completed.indexOf(state.goals.completed.find(e => e.id === payload.id))
      if (u > -1) {
        state.goals.uncompleted.splice(u, 1, payload)
      }
      if (c > -1) {
        state.goals.completed.splice(c, 1, payload)
      }
    }
  },
  actions: {
    async fetchGoals ({ commit, getters }, payload) {
      commit('clearError')
      try {
        const x = await axios({
          method: 'get',
          headers: {
            Authorization: 'Bearer ' + getters.userSecurity.token,
            'X-Api-Key': getters.userSecurity.key
          },
          url: getters.baseURL + 'apicall/coach/getgoals',
          params: {
            month: payload.month,
            year: payload.year,
            client_id: getters.activeUser
          }
        })
        commit('updateGoals', x.data)
      } catch (error) {
        console.error(error.response)
        commit('setError', error)
      }
    },
    async sendGoals ({ commit, getters }, payload) {
      commit('clearError')

      // base
      function base (ms) {
        return new Promise(resolve => {
          // setTimeout(resolve, ms)
          setTimeout(() => {
            resolve(
              'DONE'
            )
          }, ms)
        })
      }
      // base the end

      try {
        const v = await base(1000)
        await axios({
          method: 'post',
          headers: {
            Authorization: 'Bearer ' + getters.userSecurity.token,
            'X-Api-Key': getters.userSecurity.key
          },
          url: getters.baseURL + 'apicall/coach/addgoal',
          params: {
            title: payload.title,
            month: payload.month,
            year: payload.year,
            type: payload.type,
            priority: payload.priority,
            client_id: getters.activeUser
          }
        })
        console.log(v)
        commit('pushGoals', payload)
      } catch (error) {
        console.error(error.response)
        commit('setError', error)
      }
    },
    async changeGoals ({ commit, getters }, payload) {
      console.log(payload)
      // base
      function base (ms) {
        return new Promise(resolve => {
          // setTimeout(resolve, ms)
          setTimeout(() => {
            resolve(
              'DONE'
            )
          }, ms)
        })
      }
      // base the end

      try {
        const v = await base(1000)
        // await axios({
        //   method: 'post',
        //   headers: {
        //     Authorization: 'Bearer ' + getters.userSecurity.token,
        //     'X-Api-Key': getters.userSecurity.key
        //   },
        //   url: getters.baseURL + 'apicall/coach/addevent',
        //   params: {
        //     title: payload.title,
        //     description: payload.description,
        //     event_start: payload.full_time.t_start,
        //     event_end: payload.full_time.t_end,
        //     event_date: payload.start,
        //     client_id: payload.clients[0].client_id
        //   }
        // })
        console.log(v)
        commit('changeGoals', payload)
      } catch (error) {
        console.error(error.response)
        commit('setError', error)
      }
    }
  },
  getters: {
    goals (state) {
      return state.goals
    },
    one_goals: (state) => (id) => {
      const c = [...state.goals.uncompleted, ...state.goals.completed]
      return c.find((e) => {
        return e.id === id
      })
    }
  }
}
