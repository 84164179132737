import axios from 'axios'
import Basic from './basic-profile'
import Personal from './personal-info'
import Information from './information'
import Achievements from './achievements'

export default {
  state: {
    user: '',
    userSecurity: JSON.parse(localStorage.getItem('usersecurity')) || null
  },
  mutations: {
    updateUserInfo (state, payload) {
      state.user = payload
    },
    loginUser (state, payload) {
      state.userSecurity = payload
    },
    logoutUser (state) {
      state.userSecurity = null
    }
  },
  actions: {
    async fetchUserInfo ({ commit, getters }) {
      commit('clearError')
      try {
        const v = await axios({
          method: 'get',
          headers: {
            Authorization: 'Bearer ' + getters.userSecurity.token,
            'X-Api-Key': getters.userSecurity.key
          },
          url: getters.baseURL + 'apicall/coach/profile'
        })
        commit('updateUserInfo', v.data)
      } catch (error) {
        console.error(error.response)
        commit('setError', error)
      }
    },
    async loginUser ({ commit, getters }, payload) {
      try {
        // const val = await base(1500)
        const v = await axios({
          method: 'get',
          url: getters.baseURL + 'apicall/init?check=' + payload,
          headers: {
            'Access-Control-Allow-Origin': '*'
          }
        })
        localStorage.setItem('usersecurity', JSON.stringify(v.data))
        commit('loginUser', v.data)
      } catch (error) {
        localStorage.removeItem('usersecurity')
        commit('setError', error)
      }
    },
    async logoutUser ({ commit }) {
      localStorage.removeItem('usersecurity')
      commit('logoutUser')
    }
  },
  getters: {
    baseURL (state, getters, rootState) {
      return rootState.baseURL
    },
    user (state) {
      return state.user
    },
    userSecurity (state) {
      return state.userSecurity
    }
  },
  modules: {
    Basic,
    Personal,
    Information,
    Achievements
  }
}
