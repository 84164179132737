<template>
  <div>
    <div
      id="content-block"
      class="sp-left sp-top"
      :class="{'sp-no': hideSide}"
    >
      <div class="container-cs">
        <aside-nav
          v-if="!hideSide"
        ></aside-nav>
        <router-view/>
        <template v-if="user_chat_login_is">
          <chat-comet-incoming-call @action="actionHandler"/>
        </template>
        <template v-if="user_chat_login_is">
          <div class="notifications-message-wrapper">
            <chat-comet-notifications-message></chat-comet-notifications-message>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import AsideNav from '@/components/AsideNav'
import ChatCometIncomingCall from '@/components/ChatComet/ChatCometCalls/ChatCometIncomingCall/index'
import ChatCometNotificationsMessage from '@/components/ChatComet/ChatCometNotificationsMessage/index'

export default {
  data () {
    return {}
  },
  computed: {
    hideSide: function () {
      return this.$route.meta.hideSideNav
    },
    user_chat_login_is: function () {
      return this.$store.getters.user_chat_login_is
    },
    chat_active: function () {
      return this.$store.getters.chat_active
    }
  },
  methods: {
    scrollHG: function () {
      const $e = document.querySelectorAll('.height-js')
      $e.forEach((e) => {
        const t = e.getBoundingClientRect().top + pageYOffset
        const wh = window.innerHeight
        e.style.minHeight = wh - (t + 24) + 'px'
      })
    },
    actionHandler (e) {
      if (this.chat_active) {
        this.$root.$refs.chat.actionHandler(e)
      }
    }
  },
  components: {
    AsideNav,
    ChatCometIncomingCall,
    ChatCometNotificationsMessage
  }
}
</script>

<style lang="scss">
.block-layout{
  border-radius: 16px;
  background-color: #fff;
  padding: 24px;
}
#content-block.sp-no{
  padding: 0!important;
}
</style>
