import Plan from './plan'
import Notes from './notes'
import Goals from './goals'
import Material from './materials'
import axios from 'axios'
export default {
  state: {
    users: '',
    active_user_id: null,
    active_user_item: null,
    payments: '',
    basePost: '',
    tab_active: 'tab-1'
  },
  mutations: {
    updateUsers (state, payload) {
      state.users = payload
    },
    updateUsersActiveUser (state, payload) {
      if (payload !== null) {
        state.active_user_id = payload.uid
      }
      state.active_user_item = payload
    },
    cleanUsersActiveUser (state) {
      state.active_user_id = null
      state.active_user_item = null
    },
    updatePayments (state, payload) {
      state.payments = payload
    },
    updateBasePost (state, payload) {
      state.basePost = payload
    },
    change_tab_active (state, payload) {
      state.tab_active = payload
    }
  },
  actions: {
    async fetchUsers ({ commit, getters }) {
      commit('clearError')
      try {
        const v = await axios({
          method: 'get',
          headers: {
            Authorization: 'Bearer ' + getters.userSecurity.token,
            'X-Api-Key': getters.userSecurity.key
          },
          url: getters.baseURL + 'apicall/coach/clients'
        })
        console.log(v)
        commit('updateUsers', v.data)
      } catch (error) {
        console.error(error.response)
        commit('setError', error)
      }
    },
    async fetchPayments ({ commit }) {
      commit('clearError')
      commit('setLoading', true)

      // base
      function base (ms) {
        return new Promise(resolve => {
          // setTimeout(resolve, ms)
          setTimeout(() => {
            resolve(
              [
                {
                  date: 'April 08, 2021',
                  description: 'In-Depth Interview Plus',
                  amount: '20.00',
                  invoiceLink: '#'
                },
                {
                  date: 'April 02, 2021',
                  description: 'In-Depth Interview',
                  amount: '20.00',
                  invoiceLink: '#'
                }
              ]
            )
          }, ms)
        })
      }
      // base the end

      try {
        const v = await base(1000)
        commit('updatePayments', v)
        commit('setLoading', false)
      } catch (error) {
        commit('setError', error)
        commit('setLoading', false)
      }
    },
    async fetchBasePost ({ commit }) {
      commit('clearError')
      commit('setLoading', true)

      // base
      function base (ms) {
        return new Promise(resolve => {
          // setTimeout(resolve, ms)
          setTimeout(() => {
            resolve(
              [
                {
                  title: 'The Complete JavaScript Course 2021: From Zero to Expert!',
                  imageURL: 'https://images.ctfassets.net/hrltx12pl8hq/6TOyJZTDnuutGpSMYcFlfZ/4dfab047c1d94bbefb0f9325c54e08a2/01-nature_668593321.jpg',
                  author: 'Jonas Schmedtmann',
                  reviews: 99308,
                  rating: 4.3,
                  topic2: ['1', '2'],
                  topic: ['JavaScript', 'Python'],
                  platform: 'Udemy',
                  level: 'level-2',
                  date: '2015-03-25'
                },
                {
                  title: 'Web-developement to 2021',
                  imageURL: 'https://images.ctfassets.net/hrltx12pl8hq/6TOyJZTDnuutGpSMYcFlfZ/4dfab047c1d94bbefb0f9325c54e08a2/01-nature_668593321.jpg',
                  author: 'Robert Fox',
                  reviews: 6468,
                  rating: 3.8,
                  topic2: ['1', '2'],
                  topic: ['HTML5', 'CSS3', 'Courses'],
                  platform: 'Coursera',
                  level: 'level-1',
                  date: '2021-03-25'
                },
                {
                  title: 'Modern JavaScript + Vue from scratch on real projects.',
                  imageURL: 'https://images.ctfassets.net/hrltx12pl8hq/6TOyJZTDnuutGpSMYcFlfZ/4dfab047c1d94bbefb0f9325c54e08a2/01-nature_668593321.jpg',
                  author: 'Dianne Russell',
                  reviews: 4513,
                  rating: 4.4,
                  topic2: ['1', '2'],
                  topic: ['JavaScript'],
                  platform: 'Udemy',
                  level: 'level-3',
                  date: '2021-04-14'
                },
                {
                  title: 'The Complete Guide to Python 3: From Beginner to Expert',
                  imageURL: 'https://images.ctfassets.net/hrltx12pl8hq/6TOyJZTDnuutGpSMYcFlfZ/4dfab047c1d94bbefb0f9325c54e08a2/01-nature_668593321.jpg',
                  author: 'Kathryn Murphy',
                  reviews: 9899,
                  rating: 4.1,
                  topic2: ['dd'],
                  topic: ['Python'],
                  platform: 'Udemy',
                  level: 'level-4',
                  date: '2021-04-13'
                },
                {
                  title: 'React + Redux - Professional Development',
                  imageURL: 'https://images.ctfassets.net/hrltx12pl8hq/6TOyJZTDnuutGpSMYcFlfZ/4dfab047c1d94bbefb0f9325c54e08a2/01-nature_668593321.jpg',
                  author: 'Arlene McCoy',
                  reviews: 4684,
                  rating: 4.0,
                  topic2: [],
                  topic: ['React', 'Redux'],
                  platform: 'Coursera',
                  level: 'level-5',
                  date: '2021-02-25'
                },
                {
                  title: 'Android development - from scratch to professional. Complete course',
                  imageURL: 'https://images.ctfassets.net/hrltx12pl8hq/6TOyJZTDnuutGpSMYcFlfZ/4dfab047c1d94bbefb0f9325c54e08a2/01-nature_668593321.jpg',
                  author: 'Ralph Edwards',
                  reviews: 5489,
                  rating: 3.9,
                  topic2: ['99'],
                  topic: ['Java', 'Kotlin'],
                  platform: 'Coursera',
                  level: 'level-5',
                  date: '2021-01-22'
                }
              ]
            )
          }, ms)
        })
      }
      // base the end

      try {
        const v = await base(1000)
        commit('updateBasePost', v)
        commit('setLoading', false)
      } catch (error) {
        commit('setError', error)
        commit('setLoading', false)
      }
    }
  },
  getters: {
    users (state) {
      return state.users
    },
    one_user: (state) => (id) => {
      return state.users.find((e) => {
        return e.client_id === id
      })
    },
    payments (state) {
      return state.payments
    },
    basePost (state) {
      return state.basePost
    },
    activeUser (state) {
      return state.active_user_id
    },
    activeUserItem (state) {
      return state.active_user_item
    },
    tab_active (state) {
      return state.tab_active
    }
  },
  modules: {
    Plan,
    Notes,
    Goals,
    Material
  }
}
